import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";
const API = apiPath.basics;
const contractor = apiPath.contractor;
//查询承运商名称下拉
export function getContractorsCarrierType(params) {
  return axios.get(API + "/select/contractors/carrier", { params });
}
//查询承包商名称下拉
export function getContractorsType(params) {
  return axios.get(contractor + "/select/contractors", {
    params: {
      orgCodeInList: params.orgCode
    }
  });
}
//记分行为大类下拉
export function getSelectScoreClassifyType(params) {
  return axios.get(API + "/select/contractors/score-classify", { params });
}
//记分行为小类下拉
export function getSelectScoreClassifyItemType(params) {
  return axios.get(API + "/select/contractors/score-item", { params });
}
//不良行为记录(分页)
export function getBadBehaviorRecordPage(params) {
  return axios.get(API + "/page/contractors/illegal-score-record", { params });
}
//不良行为记录(不分页)
export function getBadBehaviorRecordList(params) {
  return axios.get(API + "/list/contractors/illegal-score-record", { params });
}
//查询承包商、承运商不良行为记分记录详情
export function getBadBehaviorRecordInfo(params) {
  return axios.get(API + `/info/contractors/illegal-score-record/${params}`);
}

//添加承包商、承运商不良行为记分记录
export function addBadBehaviorRecord(params) {
  return axios.post(API + `/add/contractors/illegal-score-record`, params);
}
//查询承包商、承运商所属部门信息
export function getContractorDeptInfo(params) {
  return axios.get(API + `/info/contractors/department`, { params });
}
//查询设施所属部门信息
export function getTankAreasDeptInfo(params) {
  return axios.get(API + `/info/facilities/department`, { params });
}
