<template>
  <div>
    <van-nav-bar
      left-arrow
      fixed
      title="不良行为记录"
      @click-left="$router.back()"
    />
    <div v-if="loaded" class="form-box" :loading="loaded">
      <van-form ref="form" class="form van-form__text-color">
        <van-field
          v-model="form.partnerType"
          name="contractorMaintain"
          label="承包商/承运商"
          label-width="120px"
          placeholder="请选择"
          :disabled="!editable"
          clearable
          :required="editable"
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: '',
              trigger: 'onChange'
            }
          ]"
        >
          <template #input>
            <span v-if="!editable" class="disabled-color">
              {{ `${Number(form.partnerType) === 1 ? "承包商" : "承运商"}` }}
            </span>
            <van-radio-group
              v-else
              v-model="form.partnerType"
              direction="horizontal"
              :disabled="!editable"
              required
              @change="partnerTypeChange"
            >
              <van-radio name="1">承包商</van-radio>
              <van-radio name="2">承运商</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          key="违章日期"
          :required="editable"
          :is-link="editable"
          :rules="[{ required: true, message: '请选择违章日期' }]"
          :value="form.illegalDate"
          label="违章日期"
          clickable
          :disabled="!editable"
          input-align="right"
          name="trainingDate"
          placeholder="请选择"
          readonly
          @click="handlerTypesClick($event, 'illegalDate')"
        />
        <van-field
          v-model="form.partnerName"
          clickable
          label-width="160px"
          :is-link="editable"
          name="partnerName"
          label="承包商/承运商名称"
          placeholder="请选择"
          :disabled="!editable"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="handlerTypesClick($event, 'partnerId')"
        />
        <van-field
          v-show="form.partnerType === '1'"
          v-model="form.contractorPersonName"
          clickable
          label-width="160px"
          :is-link="editable"
          name="contractorPersonName"
          label="承包商人员"
          :placeholder="!editable ? '' : '请选择'"
          :disabled="!editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="handlerTypesClick($event, 'contractorPersonId')"
        />
        <van-field
          v-model="form.partnerDepartmentName"
          clickable
          name="name"
          label="承包商/承运商所属部门"
          label-width="180px"
          :disabled="true"
          maxlength="500"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          v-model="form.poiName"
          :is-link="editable"
          :rules="[{ message: '请填写部位名称' }]"
          label="违章地点"
          :disabled="!editable"
          name="poiName"
          clickable
          :placeholder="!editable ? '' : '请选择'"
          input-align="right"
          clearable
          error-message-align="right"
          readonly
          @click="handlerTypesClick($event, 'poiId')"
        />
        <van-field
          v-model="form.poiDepartmentName"
          clickable
          name="name"
          label="违章地点所属部门"
          label-width="180px"
          :disabled="true"
          maxlength="500"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          key="reason"
          v-model="form.illegalDesc"
          rows="2"
          :required="editable"
          autosize
          label="违章事件描述"
          class="textarea"
          type="textarea"
          maxlength="500"
          :disabled="!editable"
          :placeholder="`请输入内容(500字以内)`"
          :rules="[
            {
              required: true,
              message: `请输入内容(500字以内)`,
              trigger: 'onBlur'
            }
          ]"
        />
        <van-field
          v-model="form.scoreClassifyName"
          clickable
          label-width="100px"
          :is-link="editable"
          name="scoreClassifyName"
          label="记分行为大类"
          placeholder="请选择"
          :disabled="!editable"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="handlerTypesClick($event, 'scoreClassifyId')"
        />
        <van-field
          v-model="form.scoreItemName"
          clickable
          label-width="100px"
          :is-link="editable"
          name="scoreItemName"
          label="记分行为小类"
          placeholder="请选择"
          :disabled="!editable"
          :required="editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              required: true,
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="handlerTypesClick($event, 'scoreItemId')"
        />
        <van-field
          v-model="form.scoreValue"
          clickable
          name="name"
          label="记分"
          class="score-value"
          label-width="180px"
          :disabled="true"
          maxlength="500"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
        />
        <van-field
          class="file"
          name="fileList"
          label="违章照片"
          readonly
          :disabled="!editable"
        >
          <template #input>
            <FileUploader
              v-model="form.fileList"
              :max-count="5"
              :accept="FILE_ACCEPT_TYPES.IMAGE"
              :unable-upload="!editable"
            />
            <span v-if="editable" class="tips"
              >照片限制5张,单个文件不能超过10MB</span
            >
          </template>
        </van-field>
        <van-field
          v-show="form.partnerType === '1'"
          label="违规人员签字"
          label-width="100px"
          input-align="right"
        >
          <template #input>
            <!-- <Tag
              v-for="item in form.detailRecordList"
              :key="item.workerId"
              :type="item.fileId ? 'green' : 'white'"
              :tick="!!item.fileId"
              @click.native="
                handleClickTag(
                  item,
                  item.fileId,
                  item.workerId,
                  item.workerType,
                  ticketId,
                  specialTicketId
                )
              "
            >
              {{ item.workerName }}
            </Tag> -->
            <Tag
              :type="form.illegalUserFileId ? 'green' : 'white'"
              tick
              @click.native="handleClickTag(form)"
            >
              {{ form.contractorPersonName }}
            </Tag>
          </template>
        </van-field>
        <van-field
          v-model="form.disposeDepartmentName"
          clickable
          class="dispose-department"
          label-width="160px"
          :is-link="editable"
          name="disposeDepartmentName"
          label="查处部门"
          placeholder="依据账号默认显示,可修改"
          :disabled="!editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="handlerTypesClick($event, 'disposeDepartmentId')"
        />
        <van-field
          v-model="form.disposeUserName"
          clickable
          label-width="160px"
          :is-link="editable"
          name="disposeUserId"
          label="查处人"
          placeholder="依据账号默认显示,可修改"
          :disabled="!editable"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="[
            {
              message: ' ',
              trigger: 'onChange'
            }
          ]"
          @click="handlerTypesClick($event, 'disposeUserId')"
        />
      </van-form>
    </div>
    <bottom-fixed-box v-if="editable">
      <template>
        <van-button class="btn-default" @click="cancle">取消</van-button>
        <van-button
          :disabled="loading.status"
          :loading="loading.status"
          :loading-text="loading.text"
          class="btn-primary"
          @click="save"
          >提交</van-button
        >
      </template>
    </bottom-fixed-box>
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
    <add-facility
      v-model="facilityMap"
      :visible="facilityVisible"
      activated="14"
      auth="org"
      title="违章地点"
      @close="facilityVisible = false"
      @confirm="onFacilityConfirm"
    />
    <cascade-department
      v-model="form.disposeDepartmentId"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <select-person
      :choose-value="chooseValue"
      title="查处人"
      :visible="executorVisiable"
      position="bottom"
      :multiple="false"
      @close="executorVisiable = false"
      @confirm="onChooseExecutor"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        type="date"
        @cancel="showTimePicker = false"
        @confirm="onDatetimeConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import {
  getContractorsCarrierType,
  getContractorsType,
  getBadBehaviorRecordInfo,
  addBadBehaviorRecord,
  getContractorDeptInfo,
  getTankAreasDeptInfo,
  getSelectScoreClassifyType,
  getSelectScoreClassifyItemType
} from "@/api/basics/contractor";
import { getAllPersonList } from "@/api/psm/contractor";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { filterEmoji, operateMessage, parseTime } from "@/utils";
import { loading } from "@/utils/constant";
export default {
  name: "DailySupervisionDetails",
  components: {
    FileUploader,
    BottomFixedBox
  },
  data() {
    return {
      id: this.$route.params.id,
      editable: this.$route.params.id !== "add" ? false : true,
      loading: { ...loading },
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      popupType: "",
      popupTypeName: "",
      popupValue: "",
      facilityMap: {
        label: "",
        value: "",
        type: ""
      },
      facilityVisible: false,
      contractorsList: [], //承包商/承运商名称展示数据
      contractorsListCys: [], //承运商数据
      contractorsListCbs: [], //承包商数据
      contractorsPersonList: [], //承包商人员字典
      scoreClassifyTypeList: [], //记分行为大类
      scoreClassifyItemTypeList: [], //记分行为小类
      popupTypesConf: {
        illegalDate: {
          title: "违章日期",
          keyName: "illegalDate"
        },
        poiId: {
          title: "违章地点",
          keyName: "poiId"
        },
        partnerId: {
          title: "承运商/承包商名称",
          keyName: "partnerName",
          typesKey: "contractorsList"
        },
        contractorPersonId: {
          title: "承包商人员",
          keyName: "contractorPersonName",
          typesKey: "contractorsPersonList"
        },
        scoreClassifyId: {
          title: "记分行为大类",
          keyName: "scoreClassifyName",
          typesKey: "scoreClassifyTypeList"
        },
        scoreItemId: {
          title: "记分行为小类",
          keyName: "scoreItemName",
          typesKey: "scoreClassifyItemTypeList"
        },
        scoreItemId: {
          title: "记分行为小类",
          keyName: "scoreItemName",
          typesKey: "scoreClassifyItemTypeList"
        },
        disposeDepartmentId: {
          title: "查处部门",
          keyName: "disposeDepartmentName"
        },
        disposeUserId: {
          title: "查处人",
          keyName: "disposeUserName"
        }
      },
      poiId: "",
      initTime: new Date(),
      FILE_ACCEPT_TYPES,
      loaded: true,
      form: {
        partnerDepartmentName: "",
        poiName: "",
        poiDepartmentName: "",
        scoreClassifyName: "",
        disposeUserName: "",
        contractorPersonName: "",
        illegalUserFileId: "",
        illegalUserFileName: "",
        subDepartment: false // cascade-department 组件没传 includeChildren 参数，默认false
      },
      showTimePicker: false,
      departmentVisible: false,
      executorVisiable: false,
      workorderTypeSltData: [],
      workorderTypeVisiable: false
    };
  },
  computed: {
    chooseValue() {
      if (!this.form.disposeUserId) return [];
      return [
        { value: this.form.disposeUserId, label: this.form.disposeUserName }
      ];
    }
  },
  async created() {
    if (this.id !== "add") {
      try {
        this.form = await getBadBehaviorRecordInfo(this.id);
      } catch (e) {
        console.log(e);
      }
    } else {
      this.form.disposeDepartmentId = this.userInfo.department;
      this.form.disposeDepartmentName = this.userInfo.departmentName;
      this.form.disposeUserId = this.userInfo.id;
      this.form.disposeUserName = this.userInfo.nickName;
    }

    this.initTypes();
  },
  methods: {
    handleClickTag(form) {
      if (!form.contractorPersonId) {
        this.$toast.fail("请先选择承包商人员");
        return;
      }
      if (form.illegalUserFileId) {
        // 已上传过，直接预览
        esign.showImage(fileUrl + form.illegalUserFileId);
        return;
      }
      esign.show(imgRes => {
        this.form.illegalUserFileId = imgRes.id;
        this.form.illegalUserFileName = imgRes.name;
        // professionLabourSignature({
        //   idList: [this.$route.params.id],
        //   fileId: imgRes.id,
        // })
        //   .then(() => {
        //     this.$toast.success("签名成功");
        //     this.form.fileId = imgRes.id;
        //     this.$router.back();
        //   })
        //   .catch((error) => {
        //     this.$toast.fail("签名失败，请重试");
        //   });
      });
    },
    partnerTypeChange(val) {
      this.form.partnerId = "";
      this.form.partnerName = "";
      this.form.partnerDepartmentId = "";
      this.form.partnerDepartmentName = "";
      this.form.contractorPersonId = "";
      this.form.contractorPersonName = "";
      this.form.illegalUserFileId = "";
      this.form.illegalUserFileName = "";
    },
    //初始化下拉字典
    initTypes() {
      try {
        const { orgCode } = this.userInfo;
        let params = { orgCode };
        getContractorsCarrierType(params).then(res => {
          this.contractorsListCys = res;
        });

        getContractorsType(params).then(res => {
          this.contractorsListCbs = res;
        });

        getSelectScoreClassifyType(params).then(res => {
          this.scoreClassifyTypeList = res;
        });
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    //查处部门弹出层确认
    refreshQuery(selectValue, includeInfo) {
      if (!includeInfo?.include) {
        this.form.disposeDepartmentName = selectValue.label;
      }
      this.form.disposeDepartmentId = this.form.disposeDepartmentId;
      this.form.subDepartment = includeInfo?.include;
      if (!this.form.disposeDepartmentId) {
        this.form.disposeDepartmentName = "";
      }
    },
    //违章地点弹出层确认
    async onFacilityConfirm(facility) {
      this.form.poiName = facility.label;
      this.form.poiType = facility.type;
      this.form.poiId = facility.value;
      if (!facility || !Object.keys(facility).length || !facility.value) {
        this.form.poiDepartmentName = "";
        this.form.poiDepartmentId = "";
        return false;
      }
      try {
        const data = await getTankAreasDeptInfo({ tankAreaId: facility.value });

        this.form.poiDepartmentName = Object.values(data)[0];
        this.form.poiDepartmentId = Object.keys(data)[0];
      } catch (e) {
        console.log("onFacilityChange -> e", e);
      }
    },
    //时间弹出层确认
    onDatetimeConfirm(time) {
      this.form.illegalDate = parseTime(time, "{y}-{m}-{d}");
      this.showTimePicker = false;
    },
    //查处人弹出层确认
    onChooseExecutor(ids = [], list = []) {
      const item = list[0] || {};
      this.form.disposeUserId = item.value;
      this.form.disposeUserName = item.label;
    },
    // onTreeConfirm({ label }) {
    //   this.form.departmentName = label;
    //   this.facilityMap.label = this.form.poiName = "";
    //   this.facilityMap.type = this.form.poiType = "";
    //   this.facilityMap.value = this.form.poiId = "";
    // },
    cancle() {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "数据未保存，确认取消？"
        })
        .then(() => {
          this.$router.back();
        });
      return;
    },
    save() {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "提交后不可修改，确认提交？"
        })
        .then(async () => {
          await this.$refs.form.validate();
          this.loading.status = true;
          let params = {
            ...this.form,
            orgCode: this.userInfo.orgCode
          };
          delete params.contractorPersonName;
          if (String(params.scoreValue).indexOf("+") > -1) {
            params.scoreValue = String(params.scoreValue).replace("+", "");
          }
          let r = await addBadBehaviorRecord(params);
          operateMessage(r, "提交不良行为记录");
          if (r) {
            this.$router.back();
          }
          this.loading.status = false;
        });
      return;
    },

    handlerTypesClick(ev, key) {
      if (!this.editable) {
        return;
      }
      if (key === "poiId") {
        this.facilityVisible = true;
        return;
      }
      if (key === "illegalDate") {
        this.form.illegalDate &&
          (this.form.illegalDate = new Date(this.form.illegalDate));
        this.showTimePicker = true;
        return;
      }
      if (key === "partnerId") {
        if (!this.form.partnerType) {
          this.$dialog
            .confirm({
              title: "提醒",
              message: "请先选择承包商/承运商"
            })
            .then(() => {});
          return;
        } else if (this.form.partnerType === "1") {
          this.contractorsList = this.contractorsListCbs;
        } else if (this.form.partnerType === "2") {
          this.contractorsList = this.contractorsListCys;
        }
      }
      if (key === "contractorPersonId") {
        if (!this.form.partnerType || !this.form.partnerId) {
          this.$dialog
            .confirm({
              title: "提醒",
              message: "请先选择承包商名称"
            })
            .then(() => {});
          return;
        } else {
          this.contractorsPersonList.forEach(item => {
            item.label = item.contractorPersonName;
            item.value = item.id;
          });
        }
      }
      if (key === "scoreItemId") {
        if (!this.form.scoreClassifyId) {
          this.$dialog
            .confirm({
              title: "提醒",
              message: "请先选择记分行为大类"
            })
            .then(() => {});
          return;
        }
      }
      if (key === "disposeDepartmentId") {
        this.departmentVisible = true;
        return;
      }
      if (key === "disposeUserId") {
        this.executorVisiable = true;
        return;
      }
      // 如果之前被选中了，那就直接传递过去
      this.popupValue = this.form[key];

      const popupTypesConf = this.popupTypesConf;
      this.popupVisible = true;
      this.popupTitle = popupTypesConf[key].title;
      this.popupData = this[popupTypesConf[key].typesKey];
      this.popupType = key;
      this.popupTypeName = popupTypesConf[key].keyName;
    },
    async onPopupConfirm(row) {
      this.form[this.popupType] = row.value;
      this.form[this.popupTypeName] = row.label;
      if (this.popupType === "scoreClassifyId") {
        this.form.scoreItemId = "";
        this.form.scoreItemName = "";
        this.form.scoreValue = "";
        if (row.value) {
          let params = { scoreClassifyId: row.value };
          this.scoreClassifyItemTypeList = await getSelectScoreClassifyItemType(
            params
          );
        }
      } else if (this.popupType === "partnerId") {
        this.form.partnerDepartmentId = "";
        this.form.partnerDepartmentName = "";
        this.form.contractorPersonId = "";
        this.form.contractorPersonName = "";
        this.form.illegalUserFileId = "";
        this.form.illegalUserFileName = "";
        if (row.value) {
          // const { orgCode } = this.userInfo;
          let params1 = { contractorId: this.form.partnerId };
          getAllPersonList(params1).then(res => {
            this.contractorsPersonList = res;
          });
          let params2 = {
            partnerType: this.form.partnerType,
            contractorId: this.form.partnerId
          };
          let data = await getContractorDeptInfo(params2);
          this.form.partnerDepartmentId = Object.keys(data)[0];
          this.form.partnerDepartmentName = Object.values(data)[0];
        }
      } else if (this.popupType === "scoreItemId") {
        this.form.scoreValue = "";
        if (row.value) {
          this.form.scoreValue =
            row.scoreType === 1 ? `-${row.score}` : `+${row.score}`;
        }
      } else if (this.popupType === "contractorPersonId") {
        this.form.illegalUserFileId = "";
        this.form.illegalUserFileName = "";
      }
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.form-box {
  padding: 58px 0 0;
  margin: 0 0 16px;
  height: calc(100vh - 58px);
  overflow: scroll;
  .file {
    ::v-deep {
      .van-field__control {
        text-align: right;
      }
    }
  }
  .form {
    padding-bottom: 75px;
  }
}
::v-deep {
  .van-form__text-color .van-field__label {
    color: #2e2e4d;
    font-size: 14px;
  }
  .textarea {
    display: block;
    .van-cell__title {
      margin-bottom: 12px;
    }
    .van-field__control {
      background: #f3f5fa;
      border-radius: 4px;
      padding: 10px 16px;
    }
  }
  .dispose-department {
    border-top: 20px solid #f3f5fa;
  }
  .file {
    display: block;

    .van-cell__title {
      margin-bottom: 12px;
    }
    .van-field__control {
      text-align: left !important;
    }
    .FileUploader {
      width: auto;
    }
    .tips {
      align-self: flex-end;
      font-size: 12px;
      font-weight: 400;
      color: #ababb8;
    }
  }
  .score-value {
    .van-field__control {
      // display: none;
    }
  }
}

// .BottomFixedBox {
//   position: relative;
//   width: 100%;
//   height: 11.73333vw;
//   padding: 2.66667vw 0px 4.26667vw 0px;
// }
</style>
