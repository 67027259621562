var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"left-arrow":"","fixed":"","title":"不良行为记录"},on:{"click-left":function($event){return _vm.$router.back()}}}),(_vm.loaded)?_c('div',{staticClass:"form-box",attrs:{"loading":_vm.loaded}},[_c('van-form',{ref:"form",staticClass:"form van-form__text-color"},[_c('van-field',{attrs:{"name":"contractorMaintain","label":"承包商/承运商","label-width":"120px","placeholder":"请选择","disabled":!_vm.editable,"clearable":"","required":_vm.editable,"input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: '',
            trigger: 'onChange'
          }
        ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [(!_vm.editable)?_c('span',{staticClass:"disabled-color"},[_vm._v(" "+_vm._s(("" + (Number(_vm.form.partnerType) === 1 ? "承包商" : "承运商")))+" ")]):_c('van-radio-group',{attrs:{"direction":"horizontal","disabled":!_vm.editable,"required":""},on:{"change":_vm.partnerTypeChange},model:{value:(_vm.form.partnerType),callback:function ($$v) {_vm.$set(_vm.form, "partnerType", $$v)},expression:"form.partnerType"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("承包商")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("承运商")])],1)]},proxy:true}],null,false,2929357327),model:{value:(_vm.form.partnerType),callback:function ($$v) {_vm.$set(_vm.form, "partnerType", $$v)},expression:"form.partnerType"}}),_c('van-field',{key:"违章日期",attrs:{"required":_vm.editable,"is-link":_vm.editable,"rules":[{ required: true, message: '请选择违章日期' }],"value":_vm.form.illegalDate,"label":"违章日期","clickable":"","disabled":!_vm.editable,"input-align":"right","name":"trainingDate","placeholder":"请选择","readonly":""},on:{"click":function($event){return _vm.handlerTypesClick($event, 'illegalDate')}}}),_c('van-field',{attrs:{"clickable":"","label-width":"160px","is-link":_vm.editable,"name":"partnerName","label":"承包商/承运商名称","placeholder":"请选择","disabled":!_vm.editable,"required":_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.handlerTypesClick($event, 'partnerId')}},model:{value:(_vm.form.partnerName),callback:function ($$v) {_vm.$set(_vm.form, "partnerName", $$v)},expression:"form.partnerName"}}),_c('van-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.partnerType === '1'),expression:"form.partnerType === '1'"}],attrs:{"clickable":"","label-width":"160px","is-link":_vm.editable,"name":"contractorPersonName","label":"承包商人员","placeholder":!_vm.editable ? '' : '请选择',"disabled":!_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.handlerTypesClick($event, 'contractorPersonId')}},model:{value:(_vm.form.contractorPersonName),callback:function ($$v) {_vm.$set(_vm.form, "contractorPersonName", $$v)},expression:"form.contractorPersonName"}}),_c('van-field',{attrs:{"clickable":"","name":"name","label":"承包商/承运商所属部门","label-width":"180px","disabled":true,"maxlength":"500","clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.partnerDepartmentName),callback:function ($$v) {_vm.$set(_vm.form, "partnerDepartmentName", $$v)},expression:"form.partnerDepartmentName"}}),_c('van-field',{attrs:{"is-link":_vm.editable,"rules":[{ message: '请填写部位名称' }],"label":"违章地点","disabled":!_vm.editable,"name":"poiName","clickable":"","placeholder":!_vm.editable ? '' : '请选择',"input-align":"right","clearable":"","error-message-align":"right","readonly":""},on:{"click":function($event){return _vm.handlerTypesClick($event, 'poiId')}},model:{value:(_vm.form.poiName),callback:function ($$v) {_vm.$set(_vm.form, "poiName", $$v)},expression:"form.poiName"}}),_c('van-field',{attrs:{"clickable":"","name":"name","label":"违章地点所属部门","label-width":"180px","disabled":true,"maxlength":"500","clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.poiDepartmentName),callback:function ($$v) {_vm.$set(_vm.form, "poiDepartmentName", $$v)},expression:"form.poiDepartmentName"}}),_c('van-field',{key:"reason",staticClass:"textarea",attrs:{"rows":"2","required":_vm.editable,"autosize":"","label":"违章事件描述","type":"textarea","maxlength":"500","disabled":!_vm.editable,"placeholder":"请输入内容(500字以内)","rules":[
          {
            required: true,
            message: "请输入内容(500字以内)",
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.illegalDesc),callback:function ($$v) {_vm.$set(_vm.form, "illegalDesc", $$v)},expression:"form.illegalDesc"}}),_c('van-field',{attrs:{"clickable":"","label-width":"100px","is-link":_vm.editable,"name":"scoreClassifyName","label":"记分行为大类","placeholder":"请选择","disabled":!_vm.editable,"required":_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.handlerTypesClick($event, 'scoreClassifyId')}},model:{value:(_vm.form.scoreClassifyName),callback:function ($$v) {_vm.$set(_vm.form, "scoreClassifyName", $$v)},expression:"form.scoreClassifyName"}}),_c('van-field',{attrs:{"clickable":"","label-width":"100px","is-link":_vm.editable,"name":"scoreItemName","label":"记分行为小类","placeholder":"请选择","disabled":!_vm.editable,"required":_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            required: true,
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.handlerTypesClick($event, 'scoreItemId')}},model:{value:(_vm.form.scoreItemName),callback:function ($$v) {_vm.$set(_vm.form, "scoreItemName", $$v)},expression:"form.scoreItemName"}}),_c('van-field',{staticClass:"score-value",attrs:{"clickable":"","name":"name","label":"记分","label-width":"180px","disabled":true,"maxlength":"500","clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},model:{value:(_vm.form.scoreValue),callback:function ($$v) {_vm.$set(_vm.form, "scoreValue", $$v)},expression:"form.scoreValue"}}),_c('van-field',{staticClass:"file",attrs:{"name":"fileList","label":"违章照片","readonly":"","disabled":!_vm.editable},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('FileUploader',{attrs:{"max-count":5,"accept":_vm.FILE_ACCEPT_TYPES.IMAGE,"unable-upload":!_vm.editable},model:{value:(_vm.form.fileList),callback:function ($$v) {_vm.$set(_vm.form, "fileList", $$v)},expression:"form.fileList"}}),(_vm.editable)?_c('span',{staticClass:"tips"},[_vm._v("照片限制5张,单个文件不能超过10MB")]):_vm._e()]},proxy:true}],null,false,3784251652)}),_c('van-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.partnerType === '1'),expression:"form.partnerType === '1'"}],attrs:{"label":"违规人员签字","label-width":"100px","input-align":"right"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('Tag',{attrs:{"type":_vm.form.illegalUserFileId ? 'green' : 'white',"tick":""},nativeOn:{"click":function($event){return _vm.handleClickTag(_vm.form)}}},[_vm._v(" "+_vm._s(_vm.form.contractorPersonName)+" ")])]},proxy:true}],null,false,2948613166)}),_c('van-field',{staticClass:"dispose-department",attrs:{"clickable":"","label-width":"160px","is-link":_vm.editable,"name":"disposeDepartmentName","label":"查处部门","placeholder":"依据账号默认显示,可修改","disabled":!_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.handlerTypesClick($event, 'disposeDepartmentId')}},model:{value:(_vm.form.disposeDepartmentName),callback:function ($$v) {_vm.$set(_vm.form, "disposeDepartmentName", $$v)},expression:"form.disposeDepartmentName"}}),_c('van-field',{attrs:{"clickable":"","label-width":"160px","is-link":_vm.editable,"name":"disposeUserId","label":"查处人","placeholder":"依据账号默认显示,可修改","disabled":!_vm.editable,"clearable":"","input-align":"right","error-message-align":"right","rules":[
          {
            message: ' ',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){return _vm.handlerTypesClick($event, 'disposeUserId')}},model:{value:(_vm.form.disposeUserName),callback:function ($$v) {_vm.$set(_vm.form, "disposeUserName", $$v)},expression:"form.disposeUserName"}})],1)],1):_vm._e(),(_vm.editable)?_c('bottom-fixed-box',[[_c('van-button',{staticClass:"btn-default",on:{"click":_vm.cancle}},[_vm._v("取消")]),_c('van-button',{staticClass:"btn-primary",attrs:{"disabled":_vm.loading.status,"loading":_vm.loading.status,"loading-text":_vm.loading.text},on:{"click":_vm.save}},[_vm._v("提交")])]],2):_vm._e(),_c('select-popup',{attrs:{"choose-value":_vm.popupValue,"data":_vm.popupData,"title":_vm.popupTitle,"visible":_vm.popupVisible,"popup-style":{
      height: '70vh'
    }},on:{"close":_vm.onPopupClose,"confirm":_vm.onPopupConfirm}}),_c('add-facility',{attrs:{"visible":_vm.facilityVisible,"activated":"14","auth":"org","title":"违章地点"},on:{"close":function($event){_vm.facilityVisible = false},"confirm":_vm.onFacilityConfirm},model:{value:(_vm.facilityMap),callback:function ($$v) {_vm.facilityMap=$$v},expression:"facilityMap"}}),_c('cascade-department',{attrs:{"visible":_vm.departmentVisible},on:{"close":function($event){_vm.departmentVisible = false},"confirm":_vm.refreshQuery},model:{value:(_vm.form.disposeDepartmentId),callback:function ($$v) {_vm.$set(_vm.form, "disposeDepartmentId", $$v)},expression:"form.disposeDepartmentId"}}),_c('select-person',{attrs:{"choose-value":_vm.chooseValue,"title":"查处人","visible":_vm.executorVisiable,"position":"bottom","multiple":false},on:{"close":function($event){_vm.executorVisiable = false},"confirm":_vm.onChooseExecutor}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_c('van-datetime-picker',{attrs:{"type":"date"},on:{"cancel":function($event){_vm.showTimePicker = false},"confirm":_vm.onDatetimeConfirm},model:{value:(_vm.initTime),callback:function ($$v) {_vm.initTime=$$v},expression:"initTime"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }